.footer-li{
    display: flex;
    gap: 1rem;
    justify-content: center;
}
footer{
    background: var(--color-bg-variant);
}

@media screen and (min-width:280px) and (max-width:600px) {
    .footer-li{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }
}