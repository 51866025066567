.experience_icon{
    color: var(--color-white);
    transition: 0.5s all linear;
}
.experience_h4{
    color: var(--color-white);
    margin-bottom: 10px;
}
.experience_small{
   font-size: 17px;
   color: var(--color-light);
}
.experience_h2{
    color: var(--color-primary);
}
.httml{
    color: #dd4b25;
}
.csss{
    color: #3595cf;
}
.jss{
    color: #e8d44d;
}
.bss{
    color: #754db6;
}
.tw{
    color: #36b7f0;
}
.card-experience{
    background: var(--color-bg-variant);
    border-radius: 20px;
    transition: 0.5s all linear;
}
.card-experience:hover{
    /* background: var(--color-primary-variant); */
    border-radius: 20px;
    transition: 0.5s all linear;
}

.card-experience:hover .experience_icon{
    transform: scale(1.3);
    transition: 0.5s all linear;
}
 
