
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  outline: 0;
  border: 0;
  text-decoration: none;
  
}
:root{
  --color-bg: #050816;
  --color-bg-variant: #151030;
  --color-primary: #4db5ff;
  --color-primary-variant: rgba(77,181,255,0.4);
  --color-white: #fff;
  --color-light: rgba(255,255,255,0.6);
  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-ms:90%;
  --transition: all 400ms ease;  
}
html{
  scroll-behavior: smooth;
}
::-webkit-scrollbar{
  display: none;
}
body{
  font-family: 'Poppins', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
  /* background-image: url(../src/images/bg-texture.png); */
}

/* ============GENEARL STYLE */
.container{
  width: var(--container-width-lg);
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5 {
   font-weight: 500;
}
h1{
  font-weight: 2.5rem;
}
section{
  margin-top: 8rem;
  height: 100vh;

}
section > h2, section, h5{
 text-align: center;
 color: var(--color-light); 
}
section > h2{
  color: var(--color-primary);
  margin-bottom: 3rem;
}
.text-light{
  color: var(--color-light);
}
a{
  color:var(--color-primary) ;
  transition: var(--transition);
}
a:hover{
  color: var(--color-white);
}
.btn{
  width:max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}
.btn:hover{
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}
.btn-primary{
  background: var(--color-primary);
  color: var(--color-bg);
}
img{
  display: block;
  width: 100%;
  object-fit: cover;
}
.me{
  background: linear-gradient(var(--color-primary-variant),transparent);
  overflow: hidden;
  padding: 5rem 0rem 1.5rem 0rem;;
  width: 22rem;
  /* height: 50rem; */
  position: absolute;
  left: calc(50% - 11rem);
  margin-top: 2rem;
  border-radius: 12rem 12rem 0 0 !important;
}
header{
  height: 110vh;
  padding-top: 3rem;
  overflow: hidden;
}
.header_container{
  text-align: center;
  height: 100%;
  position: relative;
}

/* ======CTA======== */
.cta{
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

/* ========SOCIAL====== */
.social{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 5.2rem;
}
.social::after{
  content: "";
  width: 2px;
  height: 2rem;
  background: var(--color-primary);
}
.scroll_down{
  position: absolute;
  right: -2.3rem;
  bottom: 10rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem ;
}
.im{
  background-image: url("./images/herobg.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev, .swiper-button-next {

  color: var(--color-bg) !important;
}
/* =========MEDIA QUERIES */
@media screen and (max-width:1024px) {
  .container{
    width: var(--container-width-md);
  }
  section{
    margin-top: 6rem;
  }
  header{
    height: 68  vh;
  }
}

/* =========MEDIA QUERIES SMALL DEVICE*/
@media screen and (max-width:600px) {
  .container{
    width: var(--container-width-md);
  }
  section > h2{
    margin-bottom: 2rem;
  }
  header{
    height: 100vh;
  }
  .social,
  .scroll_down{
    display: none;
  }
}

