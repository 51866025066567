nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}
nav a{
    background: transparent;
    padding: 0.7rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}
nav a:hover{
    background: rgba(0, 0, 0, 0.3);
}
nav a.active{
    background: var(--color-bg-variant);
    color: var(--color-white);
}
/* Add tooltip styling */
a[title] {
    position: relative;
  }
  
  a[title]:hover:after {
    content: attr(title);
    position: absolute;
    bottom: 110%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    padding: 5px 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 5px;
    font-size: 16px;
  }
  