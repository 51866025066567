.contact-bg{
    border-radius: 20px;
    background: var(--color-bg-variant);
}
.contact-msg{
    color: var(--color-primary);
}
.form-control-lg{
    background: transparent !important;
    border: 1px solid var(--color-primary) !important;
    color: var(--color-white) !important;
}
.starContainer {
    height: 140vh !important;
}