.about_card{
    background: transparent;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 7px;
    box-shadow:  1px 0px 3px 1px var(--color-white);
    transition: 0.5s all linear;
}
.about_card:hover{
  transform: scale(1.05);
  transition: 0.5s all linear;
}
.about_icon{
    color: var(--color-primary);
    font-size: 2rem;
}
.about-h2{
    color: var(--color-primary);
}