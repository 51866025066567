
.portfolio_item{
    background-color: var(--color-bg-variant) !important;
    padding: 1.2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}
.portfolio_item:hover{
    /* border-color: var(--color-primary-variant) !important; */
    background: transparent;
}
.portfolio_img {
    /* position: relative; */
  }
  
  .image-container {
    /* position: relative; */
    width: 100%;
    height: 40vh; /* Set the height of the container */
    overflow: hidden;
  }
  
  .hover-image {
    /* position: absolute; */
    /* top: 0;
    left: 50%;
    transform: translateX(-50%); */
    transition: 0.6s all linear !important;
  }
  .parallax-card-shine{
    display: none !important;
  }
  .active{
    background-color:  var(--color-bg-variant) !important;
    color: white;
  }
  .category{
    background-origin: padding-box;
    background-color: var(--color-primary-variant) ;
    padding: 5px 10px;
    border-radius: 15px;
  }