.AA{
    height: 90px;
    width: 90px;
    border-radius: 50%;
    overflow: hidden;
}
.testimonial-main{
    border-radius: 20px;
    background: var(--color-bg-variant);
}
.swiper-button-prev, .swiper-button-next {
    color: #f5f5f5 !important;
}       